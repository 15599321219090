<template>
    <div>
        <el-dialog
            :title="DialogData.title"
            :visible.sync="DialogData.visible"
            :width="DialogData.width"
            append-to-body
            center
            v-if="DialogData.visible"
            @close='emit("close")'
        >
            <slot name="content"></slot>
            <slot name="footer"></slot>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        DialogData: {
            type: Object,
            default: {},
        },
    },
    data() {
        return {};
    },
};
</script>
<style scoped></style>
