<template>
  <div>
    <el-dialog :title="typeVal.title" :visible.sync="visible" append-to-body width="50%"
               @close="closeDialog" :validate-on-rule-change="false" :close-on-click-modal="false">
      <ShanghaiRSNTable ref="ShanghaiRSNTable" :table-headers="tableHeaders" @select="select"
                        :typeVal="typeVal"></ShanghaiRSNTable>
      <!--      <div class="dialog-footer">-->

      <!--        <el-button @click="closeDialog">取 消</el-button>-->
      <!--        <el-button type="primary" @click="save">确 定</el-button>-->
      <!--      </div>-->
    </el-dialog>
  </div>
</template>
<script>
// import mixin from '@/mixin/manifest/sh/manifestTable'
import ShanghaiRSNTable from "@/views/ShanghaiManifest/components/ShanghaiRSNTable.vue";

export default {
  // mixins: [mixin],
  name: "ManifestContactDialog",
  components: {ShanghaiRSNTable,},
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    typeVal: {
      type: Object,
      default: () => {
        return {
          title: '',
          code: ''
        }
      }
    }
  },
  watch: {
    isShow(newVal, oldVal) {
      this.visible = newVal;
      if (this.visible) {
        this.$nextTick(() => {
          this.$refs.ShanghaiRSNTable.getList();
        })
      }
    }
  },
  data() {
    return {
      visible: false,
      tableHeaders: this.RESETTABLE.shanghaiRSNMini,
    }
  },
  methods: {
    closeDialog() {
      this.visible = false;
      this.$emit('close');
    },
    select(item) {
      this.$emit('select', item)
      this.closeDialog();
    },
    save() {
      this.$emit('select',)
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="stylus">
.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
