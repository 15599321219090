<template>
  <div>
    <div class="rsnButton">
      <div>
        <el-button type="primary" @click="addGoods()" size="small">新增</el-button>
        <el-button type="danger" :disabled="this.multipleSelection.length===0" @click="deleteSomeGoods()" size="small">
          删除
        </el-button>
      </div>
      <span class="msg_div">
        <span class="title">总件数：<span class="num">{{ AllPackage }}</span></span>
        <span class="title">总毛重：<span class="num">{{ AllWeight }}</span></span>
        <span class="title">总体积：<span class="num">{{ AllVolume }}</span></span>
      </span>
    </div>
    <div class="the_table">
      <el-form :model="form" :rules="rules" ref="form">
        <el-table
            ref="multipleTable"
            border
            :data="form.goodsList"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange"
            :header-cell-style="{background: '#eef1f6',color: '#606278'}"
            :cell-style="{ 'text-align': 'center', padding: '0px' }"
            max-height="300">
          <slot name="first">
            <el-table-column fixed type="selection" width="55" align="center"></el-table-column>
          </slot>
          <el-table-column
              align="left"
              v-for="(item,index) in goodsTable"
              :key="item.prop"
              :prop="item.prop"
              :label="item.label"
              :width="item.width">
            <template slot="header">
              <span style="margin-left:10px">{{ item.label }}</span>
            </template>
            <template slot-scope="scope">
              <!--              <el-form-item :prop="'goodsList.'+scope.$index+'.billno'"-->
              <!--                            :rules="rules.billno"-->
              <!--                            v-if="item.prop === 'ctntype'">-->
              <!--                <el-select-->
              <!--                    v-enterNextInput-->
              <!--                    v-model="scope.row[item.prop]"-->
              <!--                    filterable-->
              <!--                    :style="[{ width: item.width }]">-->
              <!--                  <el-option-->
              <!--                      v-for="item1 in ctnTypes"-->
              <!--                      :key="item1.value"-->
              <!--                      :label="item1.label"-->
              <!--                      :value="item1.value">-->
              <!--                  </el-option>-->
              <!--                </el-select>-->
              <!--              </el-form-item>-->
              <!--              <el-form-item prop="boxmark"-->
              <!--                            v-else-if="item.prop === 'boxmark'">-->
              <!--                <el-select-->
              <!--                    v-enterNextInput-->
              <!--                    v-model="scope.row[item.prop]"-->
              <!--                    filterable-->
              <!--                    :style="[{ width: item.width }]">-->
              <!--                  <el-option-->
              <!--                      v-for="item1 in boxesmark"-->
              <!--                      :key="item1.value"-->
              <!--                      :label="item1.label"-->
              <!--                      :value="item1.value">-->
              <!--                  </el-option>-->
              <!--                </el-select>-->
              <!--              </el-form-item>-->
              <!--              <el-form-item prop="wraptype"-->
              <!--                            v-else-if="item.prop === 'wraptype'">-->
              <!--                <el-select-->
              <!--                    v-enterNextInput-->
              <!--                    v-model="scope.row[item.prop]"-->
              <!--                    filterable-->
              <!--                    :style="[{ width: item.width }]">-->
              <!--                  <el-option-->
              <!--                      v-for="item1 in wraptypes"-->
              <!--                      :key="item1.value + ' | ' + item1.label"-->
              <!--                      :label="item1.value + ' | ' + item1.label"-->
              <!--                      :value="item1.value + ' | ' + item1.label">-->
              <!--                  </el-option>-->
              <!--                </el-select>-->
              <!--              </el-form-item>-->
              <!--              <el-form-item prop="bstatus"-->
              <!--                            v-else-if="item.prop === 'bstatus'">-->
              <!--                <el-switch-->
              <!--                    v-model="scope.row[item.prop]"-->
              <!--                    active-color="lightgreen"-->
              <!--                    inactive-color="grey"-->
              <!--                    active-value="1"-->
              <!--                    inactive-value="0">-->
              <!--                </el-switch>-->
              <!--              </el-form-item>-->
              <el-form-item
                  :prop="'goodsList.'+scope.$index+'.'+item.prop"
                  :rules="rules[item.prop]">
                <el-select
                    v-if="item.prop === 'ctntype'"
                    v-enterNextInput
                    v-model="scope.row[item.prop]"
                    filterable
                    :style="[{ width: item.width }]">
                  <el-option
                      v-for="item1 in ctnTypes"
                      :key="item1.value"
                      :label="item1.label"
                      :value="item1.value">
                  </el-option>
                </el-select>
                <el-select
                    v-else-if="item.prop === 'boxmark'"
                    v-enterNextInput
                    v-model="scope.row[item.prop]"
                    filterable
                    :style="[{ width: item.width }]">
                  <el-option
                      v-for="item1 in boxesmark"
                      :key="item1.value"
                      :label="item1.label"
                      :value="item1.value">
                  </el-option>
                </el-select>
                <el-select
                    v-else-if="item.prop === 'wraptype'"
                    v-enterNextInput
                    v-model="scope.row[item.prop]"
                    filterable
                    :style="[{ width: item.width }]">
                  <el-option
                      v-for="item1 in wraptypes"
                      :key="item1.value + ' | ' + item1.label"
                      :label="item1.value + ' | ' + item1.label"
                      :value="item1.value + ' | ' + item1.label">
                  </el-option>
                </el-select>
                <el-switch
                    v-else-if="item.prop === 'bstatus'"
                    v-model="scope.row[item.prop]"
                    active-color="lightgreen"
                    inactive-color="grey"
                    active-value="E"
                    inactive-value="F">
                </el-switch>
                <el-input
                    v-else
                    @input="inputFilter($event,scope.row, item.prop)"
                    v-model="scope.row[item.prop]">
                </el-input>
              </el-form-item>
            </template>

          </el-table-column>
          <slot name="todo">
            <el-table-column fixed="right" label="操作" width="160" align="center">
              <template slot-scope="scope">
                <div class="btn_div">
                  <span class="copy_span" @click="copyGoods(scope.row)">复制</span>
                  <span class="delete_span" @click="showDeleteDialog(scope.row)">删除</span>
                </div>
              </template>
            </el-table-column>
          </slot>
        </el-table>
      </el-form>
    </div>
    <el-dialog title="确认要删除以下货物明细吗？" :visible.sync="deleteDialogVisible" width="30%" append-to-body center>
      <el-table :data="deleteGoodsList" style="width: 100%">
        <el-table-column prop="billno" label="提单号" width="180"></el-table-column>
        <el-table-column prop="ctnno" label="箱号" width="180"></el-table-column>
        <el-table-column prop="sealno" label="封号"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false;deleteGoodsList = []">取 消</el-button>
        <el-button type="primary" @click="deleteGoods()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  ctnTypes,
  boxesmark,
  wraptypes,
} from "@/data/manifestInformation";
import {deleteById, querySelectGoods, saveGoods, updategoods} from "@/api/shanghaiManifest";
import {setNumberFormat, setRightForrmat, setRightForrmatPlus} from "@/utils/validate";
import {math} from "@/utils/math";

export default {
  name: 'ManifestGoodsTable',
  props: {
    item: {
      type: Array,
      default: () => []
    },
    dangerGoodsType: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    item(newVal, oldVal) {
      this.form.goodsList = newVal
      this.form.goodsList.forEach(x => {
        x.isEdit = false;
      })
    },
    dangerGoodsType(newVal) {
      this.$refs.form.clearValidate();
      this.rules.dguncode.required = newVal;
      this.rules.dgclass.required = newVal;
      this.rules.hazmat.required = newVal;
      this.rules.hazmatnum.required = newVal;

    }
  },
  computed: {
    AllPackage() {
      let temp = 0;
      this.form.goodsList.forEach((item) => {
        if (item.packno) {
          temp = math.add(temp, item.packno)
          // temp += parseFloat(item.packno);
        }
      })
      return temp;
    },
    AllWeight() {
      let temp = 0;
      this.form.goodsList.forEach((item) => {
        if (item.weight) {
          // temp += parseFloat(item.weight);
          temp = math.add(temp, item.weight)
        }
      })
      return temp;
    },
    AllVolume() {
      let temp = 0;
      this.form.goodsList.forEach((item) => {
        if (item.volume) {
          temp = math.add(temp, item.volume)
          // temp += parseFloat(item.volume);
        }
      })
      return temp;
    }
  },
  data() {
    return {
      wraptypes,
      boxesmark,
      ctnTypes,
      goodsTable: this.RESETTABLE.goodsDetail,
      deleteDialogVisible: false, //删除货物弹窗
      multipleSelection: [],
      deleteGoodsList: [],
      // 弹窗数据
      DialogData: {
        width: "30%",
        visible: false,
        title: "发送VGM",
      },
      rules: {
        billno: [
          {required: true, message: "请输入提单号", trigger: "blur"},
          // {
          //   min: 3,
          //   max: 5,
          //   message: "长度在 3 到 5 个字符",
          //   trigger: "blur",
          // },
        ],
        ctnno: {required: true, message: '请输入箱号', trigger: "blur"},
        sealno: {required: true, message: '请输入封号', trigger: "blur"},
        ctntype: {required: true, message: '请选择箱型', trigger: "blur"},
        boxmark: {required: true, message: '请选择货主箱标记', trigger: "blur"},
        cargoname: {required: true, message: '请输入英文品名', trigger: "blur"},
        wraptype: {required: true, message: '请选择包装单位', trigger: "blur"},
        packno: {required: true, message: '请输入件数', trigger: "blur"},
        weight: {required: true, message: '请输入重量', trigger: "blur"},
        volume: {required: true, message: '请输入体积', trigger: "blur"},
        mark: {required: true, message: '请输入唛头', trigger: "blur"},
        dguncode: {required: false, message: '请输入危险品代码', trigger: "blur"},
        dgclass: {required: false, message: '请输入危险品类型', trigger: "blur"},
        hazmat: {required: false, message: '请输入危险品联系人', trigger: "blur"},
        hazmatnum: {required: false, message: '请输入危险品联系方式', trigger: "blur"},
      },
      form: {
        goodsList: [],//货物列表
      },
      goodsItem: {
        billno: '',
        ctnno: '',
        sealno: '',
        ctntype: '',
        boxmark: 'N-承运人箱 COC',
        cargoname: '',
        wraptype: '',
        packno: '',
        volume: '',
        weight: '',
        mark: 'N/M',
        dguncode: '',
        dgclass: '',
        hazmat: '',
        hazmatnum: '',
        bstatus: '',
        isHazardous: 0,
        isEdit: false,
        uuid: '',
      },
    }
  },
  mounted() {
  },
  methods: {
    inputFilter(value, item, key) {
      if (key === 'packno') {
        item[key] = setNumberFormat(value);
      } else if (value) {
        item[key] = setRightForrmatPlus(value);
      }
    },
    addGoods() {
      let item = JSON.parse(JSON.stringify(this.goodsItem));
      item.isEdit = true;
      item.index = this.form.goodsList.length + 1;
      this.form.goodsList.push(item);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    deleteSomeGoods() {
      //删除一堆货物
      this.deleteGoodsList = [];
      if (this.multipleSelection.length !== 0) {
        this.deleteDialogVisible = true;
        this.multipleSelection.forEach((item) => {
          this.deleteGoodsList.push(item);
        });
      } else {
        this.$message({
          message: "请选择要删除的货物！",
          type: "error",
        });
      }
    },
    deleteGoods() {
      //删除货物
      this.deleteGoodsList.forEach((item) => {
        this.form.goodsList = this.form.goodsList.filter(x =>
            x.billno !== item.billno
        )
      })
      this.deleteDialogVisible = false;
      this.deleteGoodsList = []; //删完重新赋值
      this.multipleSelection = [];
      this.$emit("itemsDeleted", this.form.goodsList)
    },
    showDeleteDialog(item) {
      this.deleteGoodsList.push(item)
      this.deleteDialogVisible = true;
    },
    save(item) {
      // 遍历判断字段是否为空
      for (let key in this.informarionArr) {
        if (!item[key]) {
          this.$message.error(this.informarionArr[key]);
          return;
        }
      }
      if (item.id) {
        updategoods(item).then((response) => {
          if (response.data.status) {
            this.$message({
              message: "修改信息成功",
              type: "success",
            });
          }
        });
      } else {
        saveGoods(item).then((response) => {
          if (response.data.status) {
            this.$message({
              message: "新增货物成功",
              type: "success",
            });
          }
        });
      }
      item.isEdit = false;
      this.$forceUpdate();
    },
    copyGoods(item) {
      let good = JSON.parse(JSON.stringify(item));
      good.isEdit = true;
      good.index = this.form.goodsList.length + 1;
      this.form.goodsList.push(good);
    },
    validate() {
      return new Promise((resolve, reject) => {
        if (this.form.goodsList.length === 0) {
          reject('请添加货物明细')
        }
        this.$refs.form.validate(valid => {
          if (valid) {
            resolve();
          } else {
            reject('请检查货物明细必填项')
          }
        })
      })
    },
  }
}

</script>


<style scoped lang="stylus">

.rsnButton {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .msg_div {
    .title {
      font-size: 16px;
    }

    .num {
      color: var(--RED-C11C20);
      font-size: 22px;
      margin-right: 1rem;
    }
  }
}

.btn_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy_span, .update_span, .delete_span {
  cursor: pointer;
  margin: 0 1rem;
}

.delete_span {
  color: red;
}

.update_span {
  color: blue;
}

.copy_span {
  color: green;
}

.the_table {
  width: 100%;
  margin-top: 1rem;

  /deep/ .el-table .cell {
    padding: 0;
  }

  /deep/ .el-input__inner {
    border-color: white;
    border-radius: 0;
  }

  /deep/ .el-input__inner:focus {
    border-color: blue;
    border-radius: 0;
  }

  .el-form-item {
    margin: 0 !important;
  }

  /deep/ .el-form-item__error {
    top: 25%;
    left: 15px;
    padding: 0;
    line-height: 1.5;
    pointer-events: none;
  }
}
</style>
