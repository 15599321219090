<template>
  <div>
    <div class="the_title" @click="shipInformation = !shipInformation">
      船舶信息
      <i :class="shipInformation?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
    </div>

    <div v-show="shipInformation">
      <el-form :inline="true" :model="shipForm" class="demo-form-inline" label-position="right"
               label-width="80px" :rules="baseRules" ref="shipForm">
        <el-form-item label="船名：" prop="vesselname">
          <el-input
              :size="size"
              class="the_base"
              v-enterNextInput
              @input="inputFilter2($event,'vesselname')"
              v-model="shipForm.vesselname"
              @blur="shipForm.vesselname =$event.target.value.trim()"
              placeholder="请输入船名,只能是大写字符和数字"
              clearable></el-input>
        </el-form-item>
        <el-form-item label="航次：" prop="voyageno">
          <el-input
              :size="size"
              class="the_base"
              ref="voyageno"
              @input="inputFilter2($event,'voyageno')"
              v-enterNextInput
              v-model.trim="shipForm.voyageno"
              @blur="shipForm.voyageno =$event.target.value.trim()"
              placeholder="请输入航次"
              clearable></el-input>
        </el-form-item>
        <el-form-item label="船代：" prop="webtype">
          <el-select
              :size="size"
              class="the_base"
              ref="webtype"
              v-enterNextInput
              v-model="shipForm.webtype"
              filterable
              placeholder="请选择"
              clearable>
            <el-option
                v-for="item in shipAgent"
                :key="item.value"
                :label="item.label"
                :value="item.label + '|' + item.value"
                @change="getLabel(item.value)"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发货地：" prop="originname">
          <el-select
              :size="size"
              class="inline-input the_base"
              ref="originname"
              v-enterNextInput
              v-model="shipForm.originname"
              filterable
              remote
              :remote-method="searchPorts"
              @blur="blur"
              v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)"
              placeholder="请输入发货地关键字"
              title="国内内支线填写时请注意！例如南京收货经上海出，发货地为南京"
              clearable>
            <el-option
                v-for="item in portOptions.slice(0,rangeNumber)"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              <!--                    <span v-html="htmlValue(item.port +'|' +item.portname,'originname',)"></span>-->
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="装货港：" prop="loadPort">
          <el-select
              :size="size"
              disabled
              class="inline-input the_base"
              ref="loadPort"
              v-enterNextInput
              v-model="shipForm.loadPort"
              filterable
              @blur="blur"
              v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)"
              :filter-method="filterPorts"
              remote
              :remote-method="searchPorts"
              placeholder="请输入发货地关键字"
              title="国内内支线填写时请注意！例如南京收货经上海出，发货地为南京"
              clearable>
            <el-option
                v-for="item in portOptions.slice(0,rangeNumber)"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              <!--                    <span v-html="htmlValue(item.port +'|' +item.portname,'originname',)"></span>-->
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卸货港：" prop="unloadport">
          <el-select
              :size="size"
              class="inline-input the_base"
              ref="unloadport"
              v-enterNextInput
              v-model="shipForm.unloadport"
              @blur="blur"
              v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)"
              :filter-method="filterPorts"
              filterable
              remote
              :remote-method="searchPorts"
              placeholder="请输入卸货港关键字"
              clearable
              @change="syncDestPort"
          >
            <el-option
                v-for="item in portOptions.slice(0,rangeNumber)"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              <!--                    <span v-html="htmlValue(item.port +'|' +item.portname,'unloadport',)"></span>-->
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="目的港：" prop="destportname">
          <el-select
              :size="size"
              class="inline-input the_base"
              ref="destportname"
              v-enterNextInput
              v-model="shipForm.destportname"
              filterable
              remote
              :remote-method="searchPorts"
              @blur="blur"
              v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)"
              :filter-method="filterPorts"
              placeholder="请输入目的港关键字"
              clearable>
            <el-option
                v-for="item in portOptions.slice(0,rangeNumber)"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              <!--                      <span v-html="htmlValue(item.label, 'destportname',)"></span>-->
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import {
  shipAgent,
} from "@/data/manifestInformation";
import {setRightForrmat, setRightForrmatPlus} from "@/utils/validate";
import {getAllSelectData} from "@/api/shanghaiManifest";

export default {
  name: "ShipFormModule",
  props: {
    size: {
      type: String,
      default: "medium"
    },
    // portsList: {
    //   type: Array,
    //   default: () => []
    // },
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  watch: {
    // portsList(newVal) {
    //   this.portOptions = newVal;
    // },
    item(newVal) {
      if (newVal && newVal.id) {
        for (let key in this.shipForm) {
          this.shipForm[key] = newVal[key];
        }
      }
    }
  },
  computed: {},
  data() {
    return {
      baseRules: {
        vesselname: [
          {
            required: true,
            message: "请输入船名",
            trigger: "blur",
          },
        ],
        voyageno: [
          {
            required: true,
            message: "输入航次",
            trigger: "blur",
          },
        ],
        webtype: [
          {
            required: true,
            message: "请选择船代",
            trigger: "change",
          },
        ],
        originname: [
          {
            required: true,
            message: "请输入发货地",
            trigger: "blur",
          },
        ],
        loadPort: [
          {
            required: true,
            message: "请输入装货港",
            trigger: "blur",
          },
        ],
        unloadport: [
          {
            required: true,
            message: "请输入卸货港",
            trigger: "blur",
          },
        ],
        destportname: [
          {
            required: true,
            message: "请输入目的港",
            trigger: "blur",
          },
        ],
      },
      shipAgent,
      shipForm: {
        //船舶信息
        vesselname: "", //船名
        voyageno: "", //航次
        webtype: "", //船代
        originname: "CNSHA|SHANGHAI", //发货地
        loadPort: "CNSHA|SHANGHAI", //装货港
        unloadport: "", //卸货港
        destportname: "", //目的港
      },
      rangeNumber: 10,
      portsList: [],
      portOptions: [],
      shipInformation: true, //船舶信息
    }
  },
  methods: {
    searchPorts(port) {
      if (port.length < 2) {
        return
      }
      const data = {port: port}
      getAllSelectData(data).then(({data}) => {
        this.portsList = data.data.map(x => {
          return {
            id: x.id,
            label: x.port + '|' + x.portname,
            value: x.port + '|' + x.portname
          }
        });
        this.portOptions = this.portsList;
      });
    },
    inputFilter(value, key) {
      if (value) {
        this.shipForm[key] = setRightForrmat(value);
      }
    },
    inputFilter2(value, key) {
      if (value) {
        this.shipForm[key] = setRightForrmatPlus(value);
      }
    },
    //下拉框滚动加载
    loadMore() {
      return () => this.rangeNumber += 10;
    },
    //下拉框失去焦点重置
    blur() {
      this.rangeNumber = 10;
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.shipForm.validate((valid) => {
          if (valid) {
            resolve();
          } else {
            reject('请检查船舶信息必填项')
          }
        })
      });
    },
    syncDestPort(newVal) {
      this.shipForm.destportname = newVal;
    },
    //过滤港口数据
    filterPorts(query) {
      if (query) {
        this.portOptions = this.portsList.filter(x => {
          return x.label.includes(query.toUpperCase())
        })
      } else {
        this.portOptions = this.portsList
      }
    },
  },
  mounted() {

  },
  //自定义指令
  directives: {
    'el-select-loadmore': (el, binding) => {
      // 获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
      if (SELECTWRAP_DOM) {
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,
           *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) binding.value();
        });
      }
    },
  }
}
</script>


<style scoped lang="stylus">
.the_title {
  user-select: none;
  cursor: pointer;
  padding: 0.6rem 0;
  font-size: 16px;
  text-align: center;
  background: var(--GRAY-dcdcdc);
  margin-bottom: 1rem;
}

.el-form-item {
  margin-bottom: 1rem;
}
</style>
