<template>
  <div class="sft_div">
    <div class="the_title" @click="showInfo = !showInfo">
      {{ typeVal.title }}信息
      <i :class="showInfo?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
    </div>
    <div v-show="showInfo" class="form_div">
      <div class="btns_div">
        <el-button v-if="needContact" class="rsnButton" type="primary" plain size="small" @click="syncNoticeInfo">
          同步通知人
        </el-button>
        <el-button class="rsnButton" type="warning" plain size="small" @click="getHistoryContacts">历史{{typeVal.title }}
        </el-button>
      </div>
      <el-form :inline="true" :model="form" ref="form" class="demo-form-inline" label-width="150px"
               :rules="dataRules">
        <el-form-item :label="`${typeVal.title}名称：`" prop="name">
          <el-input
              :size="size"
              @input="inputFilter2($event, 'name')"
              style="width: 120rem"
              v-enterNextInput
              v-model="form.name"
              @blur="form.name =$event.target.value.trim()"
              placeholder="请输入名称,70个字符以内"
              clearable
              maxlength="70"
              show-word-limit></el-input>
        </el-form-item>
        <el-form-item :label="`${typeVal.title}地址：`" prop="address">
          <el-input
              :size="size"
              @input="inputFilter2($event, 'address')"
              v-enterNextInput
              v-model="form.address"
              @blur="form.address =$event.target.value.trim()"
              style="width: 120rem"
              placeholder="请输入地址,70个字符以内"
              clearable
              maxlength="70"
              show-word-limit></el-input>
        </el-form-item>
        <!--        <el-form-item :label="`${typeVal.title}企业代码：`" prop="companyCode">-->
        <!--          <el-input-->
        <!--              :size="size"-->
        <!--              v-enterNextInput-->
        <!--              v-model="form.companyCode"-->
        <!--              style="width: 120rem"-->
        <!--              placeholder="请输入企业代码"-->
        <!--              @input="inputFilter2($event, 'companyCode')"-->
        <!--              clearable></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item :label="`${typeVal.title}国家代码：`" prop="country">
          <el-select
              :size="size"
              class="the_base"
              v-enterNextInput
              filterable
              @blur="blur"
              v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)"
              :filter-method="filterCountry"
              v-model="form.country"
              placeholder="请选择"
              clearable>
            <el-option
                v-for="item in countryOptions.slice(0,rangeNumber)"
                :key="item.value"
                :label=" item.label"
                :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="`${typeVal.title}联系电话：`" prop="phone">
          <el-input
              :size="size"
              class="the_base"
              v-enterNextInput
              v-model="form.phone"
              @blur="form.phone =$event.target.value.trim()"
              @input="inputFilter2($event, 'phone')"
              placeholder="请输入电话"
              clearable></el-input>
        </el-form-item>
        <!--                <template v-if="needContact">-->
        <!--                  <el-form-item label="实际联系人：" prop="contactName">-->
        <!--                    <el-input-->
        <!--                        :size="size"-->
        <!--                        class="the_base"-->
        <!--                        v-enterNextInput-->
        <!--                        v-model="form.contactName"-->
        <!--                        @blur="form.contactName =$event.target.value.trim()"-->
        <!--                        @input="inputFilter2($event, 'contactName')"-->
        <!--                        placeholder="请输入名称,70个字符以内"-->
        <!--                        clearable></el-input>-->
        <!--                  </el-form-item>-->
        <!--                  <el-form-item label="实际联系人电话：" prop="contactPhone">-->
        <!--                    <el-input-->
        <!--                        :size="size"-->
        <!--                        class="the_base"-->
        <!--                        v-enterNextInput-->
        <!--                        v-model="form.contactPhone"-->
        <!--                        @blur="form.contactPhone =$event.target.value.trim()"-->
        <!--                        placeholder="请输入电话"-->
        <!--                        clearable></el-input>-->
        <!--                  </el-form-item>-->
        <!--                </template>-->
        <!--        <el-form-item :label="`${typeVal.title}AEO代码：`" prop="aeoCode">-->
        <!--          <el-input-->
        <!--              :size="size"-->
        <!--              class="the_base"-->
        <!--              @input="inputFilter($event, 'aeoCode')"-->
        <!--              @blur="form.aeoCode =$event.target.value.trim()"-->
        <!--              v-model="form.aeoCode"-->
        <!--              title="没有AEO资质的不要乱填！留空！不要写任何内容！"-->
        <!--              clearable></el-input>-->
        <!--        </el-form-item>-->
      </el-form>
    </div>
    <ManifestContactDialog :is-show="showContactSelectDialog" :type-val="typeVal"
                           @close="showContactSelectDialog=false" @select="selectSFT"></ManifestContactDialog>
  </div>
</template>

<script>
import {setRightForrmat, setRightForrmatPlus} from "@/utils/validate";
import ManifestContactDialog from "@/views/ShanghaiManifest/components/contacts/ManifestContactDialog.vue";

export default {
  name: "SFTFormModule",
  components: {ManifestContactDialog},
  props: {
    size: {
      type: String,
      default: "medium"
    },
    item: {
      type: Object,
      default: () => {
      }
    },
    typeVal: {
      type: Object,
      default: () => {
        return {
          title: '',
          code: ''
        }
      }
    },
    countryList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    item(newVal, oldVal) {
      if (newVal && newVal.id) {
        this.form = JSON.parse(JSON.stringify(newVal));
      }
    },
    countryList(newVal) {
      this.countryOptions = newVal;
    }
  },
  computed: {
    needContact() {
      return this.typeVal.title === '收货人';
    }
  },
  data() {
    return {
      countryOptions: this.countryList,
      rangeNumber: 10,//el-select下拉框默认显示的条数
      showContactSelectDialog: false,
      form: {
        name: '',
        address: '',
        country: this.typeVal.code === 0 ? 'CN-中国' : '',
        code: '',
        phone: '',
        aeoCode: '',
        contactName: '',
        contactPhone: '',
        type: this.typeVal.code
      },
      showInfo: true,
      popShow: false,
      dataRules: {
        name: [
          {
            required: true,
            message: `请输入${this.typeVal.title}名称`,
            trigger: "blur",
          },
          {
            min: 0,
            max: 70,
            message: "长度在70个字符以内",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: `请输入${this.typeVal.title}联系电话`,
            trigger: "blur",
          },
          {pattern: /^[0-9\s]/, message: '只能输入数字', trigger: 'blur'},
        ],
        country: [
          {
            required: true,
            message: `请选择${this.typeVal.title}国家代码`,
            trigger: "change",
          },
        ],
        contactName: [
          {
            required: true,
            message: "请输入实际联系人",
            trigger: "blur",
          },
        ],
        contactPhone: [
          {
            required: true,
            message: "请输入实际联系人电话",
            trigger: "blur",
          },
          {pattern: /^[0-9\s]/, message: '只能输入数字', trigger: 'blur'},
        ],
        address: [
          {
            required: true,
            message: `请输入${this.typeVal.title}地址`,
            trigger: "blur",
          },
          {
            min: 0,
            max: 70,
            message: "长度在70个字符以内",
            trigger: "blur",
          },
        ],
        companyCode: [
          {pattern: /^[0-9a-zA-Z](?=.*\+){1,6}/, message: '必须含有+号,且前后不能有空格', trigger: 'blur'},
        ]
      },
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.item && this.item.id) {
        this.form = JSON.parse(JSON.stringify(this.item));
      }
    },
    inputFilter(value, key) {
      if (value) {
        this.form[key] = setRightForrmat(value);
      }
    },
    inputFilter2(value, key) {
      if (value) {
        this.form[key] = setRightForrmatPlus(value);
        if (this.needContact) {
          if (key === 'name') {
            this.form.contactName = this.form.name;
          } else if (key === 'phone') {
            this.form.contactPhone = this.form.phone;
          }
        }
      }
    },
    getHistoryContacts() {
      this.showContactSelectDialog = !this.showContactSelectDialog;
    },
    syncNoticeInfo(){
      this.$emit('syncNotice',this.form);
    },
    //通知人信息从收货人信息同步
    syncByRevice(data){
      this.form.phone=data.phone;
      this.form.name = data.name;
      this.form.address = data.address;
      this.form.country = data.country;
    },
    selectSFT(data) {
      this.form = data
    },
    //过滤数据
    filterCountry(query) {
      // console.log('query===', query)
      if (query) {
        this.countryOptions = this.countryList.filter(x => {
          return x.label.includes(query.toUpperCase())
        })
      } else {
        this.countryOptions = this.countryList
      }
    },
    //下拉框滚动加载
    loadMore() {
      return () => this.rangeNumber += 10;
    },
    //下拉框失去焦点重置
    blur() {
      this.rangeNumber = 10;
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve();
          } else {
            reject(`请检查${this.typeVal.title}必填项`)
          }
        })
      });
    },
  },
  //自定义指令
  directives: {
    'el-select-loadmore': (el, binding) => {
      // 获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
      if (SELECTWRAP_DOM) {
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,
           *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) binding.value();
        });
      }
    },
  }

}
</script>


<style scoped lang="stylus">
.the_title {
  user-select: none;
  cursor: pointer;
  margin-top: 1rem;
  padding: 0.6rem 0;
  font-size: 16px;
  text-align: center;
  background: var(--GRAY-dcdcdc);
  margin-bottom: 1rem;
}

.sft_div {
  position: relative;
}

.btns_div {
  position: absolute;
  right: 2rem;
  top: 4rem;

}

.rsnButton {
}

.el-form-item {
  margin-bottom: 1rem;
}
</style>
