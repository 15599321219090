<template>
  <div>
    <div class="the_button">
      <el-button type="primary" @click="save2Wait()" size="small">保存到待发送
      </el-button>
      <el-button type="primary" plain @click="save2Draft()" size="small">保存到草稿箱</el-button>
      <el-button plain size="small" @click="close">关闭</el-button>
    </div>
    <div>
      <BaseFormModule :item="baseForm" ref="baseForm" @goodsType="goodsTypeChange"></BaseFormModule>
      <ShipFormModule :item="baseForm" ref="shipForm"></ShipFormModule>
      <SFTFormModule ref="consignerForm" :item="consignerForm" :type-val="{title:'发货人',code:0}"
                     :countryList="countryList"></SFTFormModule>
      <SFTFormModule ref="consigneeForm" :item="consigneeForm" :type-val="{title:'收货人',code:1}"
                     @syncNotice="syncNotice"
                     :countryList="countryList"></SFTFormModule>
      <SFTFormModule ref="informersForm" :item="informersForm" :type-val="{title:'通知人',code:2}"
                     :countryList="countryList"></SFTFormModule>
      <div class="the_title">货物明细</div>
      <MainfestGoodsTable ref="ManifestGoodsTable" :item="goodsList" :danger-goods-type="isDangerGoods"
                          @itemsDeleted="refreshGoodsList"></MainfestGoodsTable>
    </div>
  </div>
</template>

<script>
import {
  getAllCountryCode,
  saveHead,
  getDetailById,
  getAllPorts, getAllSelectData,
} from "@/api/shanghaiManifest";
import DialogVue from "@/components/common/Dialog.vue";
import SFTFormModule from "@/views/ShanghaiManifest/components/sft/SFTFormModule.vue";
import MainfestGoodsTable from "@/views/ShanghaiManifest/components/goods/ManifestGoodsTable.vue";
import BaseFormModule from "@/views/ShanghaiManifest/components/baseInfo/BaseFormModule.vue";
import ShipFormModule from "@/views/ShanghaiManifest/components/shipInfo/ShipFormModule.vue";

export default {
  props: {
    item: {//列表传入的item
      type: Object,
      default: () => {
      }
    },
    index: {
      type: String,
      default: "0", //default 默认值，父组件不传值就显示默认
    },
    list: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {
    ShipFormModule,
    BaseFormModule,
    MainfestGoodsTable,
    SFTFormModule,
    DialogVue,
  },
  computed: {},
  data() {
    return {
      detail: {
        head: {},
        contacts: [],
        goods: []
      },
      baseForm: {},
      //发货人信息
      consignerForm: {},
      //收货人
      consigneeForm: {},
      //通知人
      informersForm: {},
      //货物明细
      goodsList: [],
      //港口列表
      portsList: [],//港口原数据
      portOptions: [],//下拉框的港口数据
      countryList: [],
      isDangerGoods:false,//是否为危险品
    };
  },
  methods: {
    htmlValue(val, name) {
      console.log('val==', val);
      console.log('name==', name);
      if (this.baseForm[name].length >= 2) {
        var name = this.baseForm[name].toUpperCase();
        return val.replace(
            new RegExp(name),
            `<font color='blue'>${name}</font>`,
        );
      } else {
        return val;
      }
    },
    init() {
      this.getCountryList();
      const params = this.$route.params
      if (params && params.id) {
        this.getDetail(params.id)
      } else {
        // create form
      }
    },
    getDetail(id) {
      getDetailById({id: id})
          .then((response) => {
            this.detail = response.data.data;
            this.baseForm = this.detail.head;
            this.consignerForm = this.detail.contacts.filter(x => x.type === 0)[0];
            this.consigneeForm = this.detail.contacts.filter(x => x.type === 1)[0];
            this.informersForm = this.detail.contacts.filter(x => x.type === 2)[0];
            this.goodsList = this.detail.goods
            console.log(response);
          })
    },
    //status，0：草稿箱，1：待发送，2：已发送
    save2Draft(status = 0) {
      console.log("base form===", this.$refs.baseForm.form);
      this.baseForm = {...this.$refs.shipForm.shipForm, ...this.$refs.baseForm.form}
      this.baseForm.completionstatus = status;
      this.baseForm.totalpackno = this.$refs.ManifestGoodsTable.AllPackage;
      this.baseForm.totalvolume = this.$refs.ManifestGoodsTable.AllVolume;
      this.baseForm.totalweight = this.$refs.ManifestGoodsTable.AllWeight;
      this.detail = {
        head: this.baseForm,
        contacts: [this.$refs.consigneeForm.form, this.$refs.consignerForm.form, this.$refs.informersForm.form],
        goods: this.$refs.ManifestGoodsTable.form.goodsList
      }
      saveHead(this.detail).then((response) => {
        if (response.data.status) {
          this.$message({
            type: "success",
            message: "保存成功！",
          });
          this.$router.back();
        } else {
          this.$message({
            type: "error",
            message: "保存失败！",
          });
        }
      });
    },
    save2Wait() {
      let list = [];
      list.push(
          this.$refs.baseForm.validate(),
          this.$refs.shipForm.validate(),
          this.$refs.consignerForm.validate(),
          this.$refs.consigneeForm.validate(),
          this.$refs.informersForm.validate(),
          this.$refs.ManifestGoodsTable.validate(),
      );
      Promise.all(list)
          .then(() => {
            this.save2Draft(1)
          }).catch((err) => {
        this.$message({type: 'error', message: err})
      })
    },
    close() {
      this.$router.back();
    },
    //获取国家代码列表
    getCountryList() {
      getAllCountryCode().then(({data}) => {
        this.countryList = data.data.map(x => {
          return {
            id: x.id,
            value: x.code + '-' + x.name,
            label: x.code + '-' + x.name
          }
        });
      });
    },
    refreshGoodsList(newList) {
      this.goodsList = newList
    },
    syncNotice(item) {
      const data = JSON.parse(JSON.stringify(item));
      this.$refs.informersForm.syncByRevice(data)
    },
    goodsTypeChange(bool){
      this.isDangerGoods = bool;
    }
  },
  mounted() {
    this.init();
  },
  //自定义指令
  directives: {
    'el-select-loadmore': (el, binding) => {
      // 获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
      if (SELECTWRAP_DOM) {
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,
           *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) binding.value();
        });
      }
    },
  }
};
</script>

<style lang="stylus" scoped>
.the_button {
  background: white;
  padding: 1rem;
  box-shadow: 10px 6px 2px 3px var(--GRAY-dcdcdc);
}

.dialog-footer {
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin: 30px auto 0;
}

.the_title {
  user-select: none;
  cursor: pointer;
  padding: 0.6rem 0;
  font-size: 16px;
  text-align: center;
  background: var(--GRAY-dcdcdc);
  margin-bottom: 1rem;
}

.el-icon-arrow-down, .el-icon-arrow-up {
  cursor: pointer;
}


</style>
