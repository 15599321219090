//*箱型
export const ctnTypes = [
    {
        value: "10RF",
        label: "10RF",
    },
    {
        value: "20GP",
        label: "20GP",
    },
    {
        value: "40GP",
        label: "40GP",
    },
    {
        value: "45GP",
        label: "45GP",
    },
    {
        value: "20FR",
        label: "20FR",
    },
    {
        value: "40FR",
        label: "40FR",
    },
    {
        value: "45FR",
        label: "45FR",
    },
    {
        value: "40HC",
        label: "40HC",
    },
    {
        value: "20HQ",
        label: "20HQ",
    },
    {
        value: "40HQ",
        label: "40HQ",
    },
    {
        value: "45HQ",
        label: "45HQ",
    },
    {
        value: "20HT",
        label: "20HT",
    },
    {
        value: "40HT",
        label: "40HT",
    },
    {
        value: "20OT",
        label: "20OT",
    },
    {
        value: "40OT",
        label: "40OT",
    },
    {
        value: "20RF",
        label: "20RF",
    },
    {
        value: "40RF",
        label: "40RF",
    },
    {
        value: "45RF",
        label: "45RF",
    },
    {
        value: "20RH",
        label: "20RH",
    },
    {
        value: "40RH",
        label: "40RH",
    },
    {
        value: "20TK",
        label: "20TK",
    },
    {
        value: "40TK",
        label: "40TK",
    },
];
//*货主箱标记
export const boxesmark = [
    {
        value: "Y-货主箱 SOC",
        label: "货主箱 SOC",
    },
    {
        value: "N-承运人箱 COC",
        label: "承运人箱 COC",
    },
];
//*包装单位
export const wraptypes = [
    {
        value: "AE",
        label: "Aerosol",
    },
    {
        value: "AM",
        label: "Ampoule",
    },
    {
        value: "AP",
        label: "Ampoule",
    },
    {
        value: "AT",
        label: "Atomizer",
    },
    {
        value: "BA",
        label: "Barrel",
    },
    {
        value: "BB",
        label: "Bobbin",
    },
    {
        value: "BC",
        label: "Bottle crate",
    },
    {
        value: "BD",
        label: "Board",
    },
    {
        value: "BE",
        label: "Bundle",
    },
    {
        value: "BF",
        label: "Ballon",
    },
    {
        value: "BG",
        label: "Bag",
    },
    {
        value: "BH",
        label: "Bunch",
    },
    {
        value: "BI",
        label: "Bin",
    },
    {
        value: "BJ",
        label: "Bucket",
    },
    {
        value: "BK",
        label: "Basket",
    },
    {
        value: "BL",
        label: "Bale",
    },
    {
        value: "BK",
        label: "Bale",
    },
    {
        value: "BO",
        label: "Bottle",
    },
    {
        value: "BP",
        label: "Ballon",
    },
    {
        value: "BQ",
        label: "Bottle",
    },
    {
        value: "BR",
        label: "Bar",
    },
    {
        value: "BS",
        label: "Bottle",
    },
    {
        value: "BT",
        label: "Bolt",
    },
    {
        value: "BU",
        label: "Butt",
    },
    {
        value: "BV",
        label: "Bottle",
    },
    {
        value: "BX",
        label: "Box",
    },
    {
        value: "BY",
        label: "Board",
    },
    {
        value: "BZ",
        label: "Bars",
    },
    {
        value: "CA",
        label: "Can",
    },
    {
        value: "CB",
        label: "Beer crate",
    },
    {
        value: "CC",
        label: "Churn",
    },
    {
        value: "CE",
        label: "Creel",
    },
    {
        value: "CF",
        label: "Coffer",
    },
    {
        value: "CG",
        label: "Cage",
    },
    {
        value: "CH",
        label: "Chest",
    },
    {
        value: "CI",
        label: "Canister",
    },
    {
        value: "CJ",
        label: "Coffin",
    },
    {
        value: "CK",
        label: "Cask",
    },
    {
        value: "CL",
        label: "Coil",
    },
    {
        value: "CN",
        label: "Cones",
    },
    {
        value: "CO",
        label: "Carboy",
    },
    {
        value: "CP",
        label: "Carboy",
    },
    {
        value: "CX",
        label: "Can",
    },
    {
        value: "CR",
        label: "Crate",
    },
    {
        value: "CS",
        label: "Case",
    },
    {
        value: "CT",
        label: "Carton",
    },
    {
        value: "CU",
        label: "Cup",
    },
    {
        value: "CV",
        label: "Cover",
    },
    {
        value: "CY",
        label: "Cylinder",
    },
    {
        value: "CZ",
        label: "Canvas",
    },
    {
        value: "DJ",
        label: "Demijohn",
    },
    {
        value: "DP",
        label: "Demijohn",
    },
    {
        value: "DR",
        label: "Drum",
    },
    {
        value: "EE",
        label: "WOODEN-PALLET",
    },
    {
        value: "EN",
        label: "Envelope",
    },
    {
        value: "FC",
        label: "Fruit crate",
    },
    {
        value: "FD",
        label: "Framed crate",
    },
    {
        value: "FI",
        label: "Firkin",
    },
    {
        value: "FL",
        label: "Flask",
    },
    {
        value: "FO",
        label: "Footlocker",
    },
    {
        value: "FP",
        label: "Filmpack",
    },
    {
        value: "FR",
        label: "Frame",
    },
    {
        value: "GB",
        label: "Gas bottle",
    },
    {
        value: "GG",
        label: "Gunny bag",
    },
    {
        value: "GI",
        label: "Girder",
    },
    {
        value: "GL",
        label: "Gunny bale",
    },
    {
        value: "GZ",
        label: "Girders",
    },
    {
        value: "HG",
        label: "Hogshead",
    },
    {
        value: "HR",
        label: "Hamper",
    },
    {
        value: "ID",
        label: "Iron drum",
    },
    {
        value: "IN",
        label: "Ingot",
    },
    {
        value: "IZ",
        label: "Ingots",
    },
    {
        value: "JC",
        label: "Jerrican",
    },
    {
        value: "JG",
        label: "Jug",
    },
    {
        value: "JR",
        label: "Jar",
    },
    {
        value: "JT",
        label: "Jutebag",
    },
    {
        value: "JY",
        label: "Jerrican",
    },
    {
        value: "KG",
        label: "Keg",
    },
    {
        value: "LG",
        label: "Log",
    },
    {
        value: "LV",
        label: "Lift van",
    },
    {
        value: "LZ",
        label: "Logs",
    },
    {
        value: "MB",
        label: "Multiply bag",
    },
    {
        value: "MC",
        label: "Milk crate",
    },
    {
        value: "MS",
        label: "Multiwall sack",
    },
    {
        value: "MT",
        label: "Mat",
    },
    {
        value: "MX",
        label: "Match box",
    },
    {
        value: "NE",
        label: "Unpacked",
    },
    {
        value: "NS",
        label: "Nest",
    },
    {
        value: "NT",
        label: "Net",
    },
    {
        value: "PA",
        label: "Packet",
    },
    {
        value: "PB",
        label: "Paper bag",
    },
    {
        value: "PC",
        label: "Parcel",
    },
    {
        value: "PE",
        label: "Pallets",
    },
    {
        value: "PG",
        label: "Plate",
    },
    {
        value: "PH",
        label: "Pitcher",
    },
    {
        value: "PI",
        label: "Pipe",
    },
    {
        value: "PK",
        label: "Package",
    },
    {
        value: "PL",
        label: "Pail",
    },
    {
        value: "PN",
        label: "Plank",
    },
    {
        value: "PO",
        label: "Pouch",
    },
    {
        value: "PP",
        label: "PIECE",
    },
    {
        value: "PS",
        label: "Pieces",
    },
    {
        value: "PT",
        label: "Pot",
    },
    {
        value: "PU",
        label: "Tray",
    },
    {
        value: "PX",
        label: "PALLET",
    },
    {
        value: "PY",
        label: "Plates",
    },
    {
        value: "PZ",
        label: "Pipes",
    },
    {
        value: "RD",
        label: "Rod",
    },
    {
        value: "RG",
        label: "Ring",
    },
    {
        value: "RL",
        label: "Reel",
    },
    {
        value: "RO",
        label: "Roll",
    },
    {
        value: "RT",
        label: "Rednet",
    },
    {
        value: "RZ",
        label: "Rods",
    },
    {
        value: "SA",
        label: "Sack",
    },
    {
        value: "SC",
        label: "Shallow crate",
    },
    {
        value: "SD",
        label: "Spindle",
    },
    {
        value: "SE",
        label: "Sea-chest",
    },
    {
        value: "SH",
        label: "Sachet",
    },
    {
        value: "SI",
        label: "Skid",
    },
    {
        value: "SK",
        label: "Skeleton case",
    },
    {
        value: "SL",
        label: "Slipsheet",
    },
    {
        value: "SM",
        label: "Sheetmetal",
    },
    {
        value: "ST",
        label: "Sheet",
    },
    {
        value: "SU",
        label: "Suitcase",
    },
    {
        value: "SW",
        label: "Shrinkwrapped",
    },
    {
        value: "SX",
        label: "SETS",
    },
    {
        value: "TB",
        label: "Tub",
    },
    {
        value: "TC",
        label: "Tea-chest",
    },
    {
        value: "TD",
        label: "Ttube",
    },
    {
        value: "TK",
        label: "Tank",
    },
    {
        value: "TN",
        label: "Tin",
    },
    {
        value: "TO",
        label: "Tun",
    },
    {
        value: "TR",
        label: "Trunk",
    },
    {
        value: "TS",
        label: "Truss",
    },
    {
        value: "TU",
        label: "Tube",
    },
    {
        value: "TY",
        label: "Tank",
    },
    {
        value: "TZ",
        label: "Tubes",
    },
    {
        value: "UN",
        label: "Unit",
    },
    {
        value: "VA",
        label: "Vat",
    },
    {
        value: "VG",
        label: "Bulk",
    },
    {
        value: "VI",
        label: "Vial",
    },
    {
        value: "VL",
        label: "Bulk",
    },
    {
        value: "VO",
        label: "Bulk",
    },
    {
        value: "VP",
        label: "Vacuumpacked",
    },
    {
        value: "VQ",
        label: "Bulk",
    },
    {
        value: "VR",
        label: "Bulk",
    },
    {
        value: "VY",
        label: "Bulk",
    },
    {
        value: "WB",
        label: "Wickerbottle",
    },
    {
        value: "WG",
        label: "Woven bag",
    },

];
export const informationList = ["ctntype", "boxmark", "wraptype", "bstatus", 'isHazardous', 'cargoname', 'mark'];
export const assignFormatList = ['cargoname', 'mark'];
export const informarionArr = {
    billno: "请填写提单号",
    ctnno: "请填写箱号",
    sealno: "请填写封号",
    ctntype: "请填写箱型",
    boxmark: "请填写货主箱标记",
    cargoname: "请填写英文品名",
    packno: "请填写件数",
    wraptype: "请填写包装单位",
    weight: "请填写毛重",
    volume: "请填写体积",
    mark: "请填写唛头",
};
// 码头数据
export const wharfData = [
    {
        value: "778059556b",
        label: "上海国际港务（集团）股份有限公司宜东集装箱码头分公司",
    },
    {
        value: "13220793-5",
        label: "上海浦东国际集装箱码头有限公司",
    },
    {
        value: "775786045",
        label: "上海盛东国际集装箱码头有限公司",
    },
    {
        value: "74212819-3",
        label: "上海沪东集装箱码头管理有限公司",
    },
    {
        value: "wgq5",
        label: "上海明东集装箱码头有限公司",
    },
    {
        value: "662445084",
        label: "上海冠东国际集装箱码头有限公司",
    },
    {
        value: "wgq2",
        label: "上海国际港务（集团）股份有限公司振东集装箱码头分公司",
    },
    {
        value: "778078730b",
        label: "上海国际港务（集团）股份有限公司罗泾分公司（散货）",
    },
    {
        value: "MA1FL3AGX",
        label: "上海国际港务（集团）股份有限公司尚东集装箱码头分公司",
    },
    {
        value: "OTHERPORT",
        label: "非上海码头",
    },
];
export const restaurants = [
    {
        value: "AACHEN|DEAAH",
    },
    {
        value: "AAHEIM|NOAAH",
    },
    {
        value: "AALBORG PORTLAND CEMENTFABRIKK|DKROR",
    },
    {
        value: "AALBORG|DKAAL",
    },
    {
        value: "AALBURG|NLALB",
    },
    {
        value: "AALEN|DEAAL",
    },
    {
        value: "AALESTRUP|DKAEY",
    },
    {
        value: "AALESUND|NOAAL",
    },
    {
        value: "AALSMEER|NLAAM",
    },
    {
        value: "AALST|BEAAB",
    },
    {
        value: "AALST|NLAAL",
    },
    {
        value: "AALTEN|NLLTE",
    },
    {
        value: "AALTER|BEAAL",
    },
    {
        value: "AARAU|CHAAR",
    },
    {
        value: "AARBERGEN|DEAAR",
    },
    {
        value: "AARDALSTANGEN|NOAAR",
    },
    {
        value: "AARDENBURG|NLAAR",
    },
    {
        value: "AARHUS|DKAHS",
    },
    {
        value: "AARLE RIXTEL|NLARI",
    },
    {
        value: "AARS|DKAZS",
    },
];
//船代
export const shipAgent = [
    {
        value: "132230158",
        label: "民生船代",
    },
    {
        value: "MA1G5DJT4",
        label: "中联船代(原中和船代)",
    },
    {
        value: "71785140-0",
        label: "鹏华船务",
    },
    {
        value: "13453884-2",
        label: "华港船代",
    },
    {
        value: "560100333",
        label: "顺德船代",
    },
    {
        value: "132210843",
        label: "上海中外运",
    },
    {
        value: "150016700",
        label: "航华船代",
    },
    {
        value: "717859429",
        label: "鹏海船代",
    },
    {
        value: "132208081",
        label: "上海外代",
    },
    {
        value: "133747781",
        label: "联合船代",
    },
];
//货物类型
export const goodsType = [
    {
        value: "S-普通货物",
        label: "普通货物",
    },
    {
        value: "R-冷冻品",
        label: "冷冻品",
    },
    {
        value: "D-危险品",
        label: "危险品",
    },
];
//*付款方式
export const payWay = [
    {
        value: 'P-预付',
        label: "预付",
    },
    {
        value: "C-到付",
        label: "到付",
    },
    {
        value: 'E-第三地付款',
        label: "第三地付款",
    },
];
//*提单类型
export const billType = [
    {
        value: "ORI-正本提单",
        label: "BILL OF LADING",
    },
    {
        value: "EXP-海运单",
        label: "WAYBILL",
    },
    {
        value: "TER-电放",
        label: "TER-电放",
    },
];
export const transportClause = [
    {
        value:'CY-CY',
        label:'CY-CY'
    },
    {
        value:'CY-CFS',
        label:'CY-CFS'
    },
    {
        value:'CFS-CY',
        label:'CFS-CY'
    },
    {
        value:'CFS-CFS',
        label:'CFS-CFS'
    },
    {
        value:'CY-DOOR',
        label:'CY-DOOR'
    },
    {
        value:'CFS-DOOR',
        label:'CFS-DOOR'
    },
    {
        value:'DOOR-CY',
        label:'DOOR-CY'
    },
    {
        value:'DOOR-CFS',
        label:'DOOR-CFS'
    },
    {
        value:'DOOR-DOOR',
        label:'DOOR-DOOR'
    },
    {
        value:'CY-RAMP',
        label:'CY-RAMP'
    },
    {
        value:'CY-FREE OUT',
        label:'CY-FREE OUT'
    },
    {
        value:'CY-LINER OUT',
        label:'CY-LINER OUT'
    },
    {
        value:'DR-FREE OUT',
        label:'DR-FREE OUT'
    },
    {
        value:'DR-LINER OUT',
        label:'DR-LINER OUT'
    },
    {
        value:'TACKLE-CY',
        label:'TACKLE-CY'
    },
    {
        value:'CY-TACKLE',
        label:'CY-TACKLE'
    },
    {
        value:'TACKLE-CFS',
        label:'TACKLE-CFS'
    },
    {
        value:'RAMP-CY',
        label:'RAMP-CY'
    },
    {
        value:'FEE IN-CY',
        label:'FEE IN-CY'
    },
    {
        value:'FREE IN-D',
        label:'FREE IN-D'
    },{
        value:'LINER IN-CY',
        label:'LINER IN-CY'
    },{
        value:'LINER IN-DR',
        label:'LINER IN-DR'
    },
    {
        value:'CY-SHIPS HOOK',
        label:'CY-SHIPS HOOK'
    },
    {
        value:'DOOR-RAMP',
        label:'DOOR-RAMP'
    },
    {
        value:'RAMP-RAMP',
        label:'RAMP-RAMP'
    },
    {
        value:'RAMP-CFS',
        label:'RAMP-CFS'
    },


];
//判断输入内容只能是数字和字母，且字母自动转大写
export const setRightFormat = function (val) {
    if (val) {
        let codeReg = new RegExp("[A-Za-z0-9-\ . ]+"), //正则 英文+数字；
            len = val.length,
            str = "";
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i])) {
                str += val[i].toUpperCase();
            }
        }
        val = str;
        return val;
    }
}
